import React from "react";
import Slider from "react-slick";
import "../slick_video_slider/slick_video_slider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const IframeVideoSlick = ({ linkList, slickSettings }) => {
  function YouTubeGetID(url) {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
  }
//console.log('linkList',linkList)
  return (
    <div className="slickVideoSlider">
      <Slider {...slickSettings}>
        {linkList.map((link, key) => {
          let videoId = YouTubeGetID(link.url);
          let thumbnail = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
          return (
            <div className="thumb" key={key}>
              <a href={link.url} className="ytlink ratio ratio-16x9" target="_blank" rel="noreferrer">
              <img
                src={thumbnail}
                alt="youtube-thumbnail"
                height="100px"
                width="170px"
              />
            </a>
            <h4 className="video-title">{link.title}</h4>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default IframeVideoSlick;
