import React from "react";
import {Row, Col, Card} from "react-bootstrap";
import "./proximos_eventos.css";
import Button from "../buttons/button"
import More_info from "./more_info";
import { SalWrapper } from "react-sal";

const Proximos_eventos = ({nextEvents}) => {

    return(
      <Row xs={1} md={2} className="neContainer justify-content-center g-3 g-md-3">
        {nextEvents.map((entry, key) => {
          let performer = []
          entry.neInfo.data.forEach((p) => {
            performer.push({
                "@type": p.position,
                "name": p.name
            })
          })

          return (
            <>
              <Col className="d-flex" key={key}>
              <SalWrapper>
              <Card className="neCard flex-fill"
                    data-sal="fade"
                    data-sal-delay={key * 50}
                    data-sal-duration="200"
              >
                <div
                    className="neBanner"
                    style={{
                      backgroundImage: `url(${entry.neBanner})`,
                      backgroundSize: "cover",
                    }}
                >

                </div>
                <Card.Header className="neHeader">
                    <span className="neTopic">{entry.neTopic}</span>
                    <h2 className="card-title neTitle"><div dangerouslySetInnerHTML={ {__html: entry.neTitle} } /></h2>
                </Card.Header>
                <div className="card-body neContent">
                    <h3 className="neDate" ><div dangerouslySetInnerHTML={ {__html: entry.neDate} } /></h3>
                    {(entry.neStatus === 2 && entry.neLinkLive !== undefined) ? (
                    <div className="py-3"><Button variant="secondary neButton" text="Ingresar" onClick={() => { window.open(`/evento-en-vivo`, "_blank") }} /></div>
                    ):(
                    <div className="py-3"><Button variant="secondary neButton" text="Inscribite" onClick={() => { window.open(`${entry.neLink}`, "_blank") }} /></div>
                    )}
                  <div className="card-text neText">
                      <div dangerouslySetInnerHTML={ {__html: entry.neText} } />
                  </div>

                </div>
                  <div className="card-footer neFooter">
                  {entry.neInfo !== undefined && (
                      <More_info cont={entry.neInfo} />
                  )}
                  </div>
                {/*<div className="card-footer neFooter">
                    <More_info cont={entry.neInfo} />
                </div>*/}
              </Card>
              </SalWrapper>
              </Col>

              <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Event",
                        "name": "${entry.neTitle}",
                        "description": "${entry.neText}",
                        "startDate": "${entry.neDateDDE}",
                        "eventStatus": "https://schema.org/EventScheduled",
                        "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
                        "location": {
                            "@type": "VirtualLocation",
                            "url": "${entry.neLink}"
                        },
                        "performer": ${JSON.stringify(performer)}
                    }
                `}
            </script>
            </>
          );
        })}
      </Row>
  );
};

export default Proximos_eventos;
