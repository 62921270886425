// Step 1: Import React
import React, {useEffect, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Layout } from "../components/Layout";
import ProductBanner from "../components/product_banners/product_banners";
import Button from '../components/buttons/button'
import Banner from "../imgs/bg_eventos.jpg";
import ProximosEventos from "../components/proximos_eventos/proximos_eventos";
import SlickVideoSlider from "../components/slick_video_slider/skick_video_slider";
import { SalWrapper } from "react-sal";
import "./eventos.css";
import YTicon from "../imgs/yt-icon.svg"
import SuscribeTalks from "../components/suscribe_talks/suscribe_talks";
import {apiRequest} from "../apiRquest/apiRequest";
import EventImage from "../imgs/banners-ballanz-04.jpg"

let text = `Te invitamos a ser parte de <span class='highlightsbold'>nuestros eventos y capacitaciones.</span>`;
const pageTitle = 'Inscribite a nuestros Eventos Online';
const pageDesc = 'Descubrí todos los eventos y capacitaciones gratuitas de los que podés ser parte. Trataremos temáticas de actualidad, información sobre el mercado de capitales y más.';
const EventosPage = () => {
    const [events, setEvents] = useState([]);
    const [loadingEvents, setLoadingEvents] = useState(false);
    const [videos, setVideos] = useState([]);
    const [loadingVideos, setLoadingVideos] = useState(false);

    let nextEvents = [
        {
            neBanner: "/imgs/art-investmentupdates-cedears.jpg",
            neTopic: "Investments Update",
            neTitle: "CEDEARs",
            neLink: "https://register.gotowebinar.com/register/2618084942788655117?source=home",
            neDate: "Martes 3 de Mayo <span>17hs</span>",
            neText: "Earnings season update. Te contamos dónde encontrar oportunidades de inversión en CEDEARs según los balances trimestrales de las principales compañías del mundo.",
            neInfo: "<h6>Oradores</h6><ul><li>Paulino Seoane <br/><span style='color:#b4b4b4'>Head de Investment Ideas</span></li></ul>",
        }
    ];

   /* let videoList = [
        {
            url: "https://www.youtube.com/watch?v=M-CzOR7kvLM",
            title:"CEDEARs de ETFs"
        },
        {
            url: "https://www.youtube.com/watch?v=REN3jzXQzjw",
            title:"Análisis Técnico"
        },

        {
            url: "https://www.youtube.com/watch?v=s7S-9knIWPo",
            title:"CEDEARs"
        },
        {
            url: "https://www.youtube.com/watch?v=RmL4KBWTXPA",
            title:"Cómo invertir en Balanz"
        }
    ];*/

    const slickSettings = {
        centerMode: false,
        centerPadding: "0px",
        initialSlide: 0,
        slidesToShow: 4,
        infinite: false,
        dots: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 992,
                settings: { centerMode: false, slidesToShow: 3, slidesToScroll: 3, infinite: false }
            },
            {
                breakpoint: 576,
                settings: {  centerMode: false, slidesToShow: 2, slidesToScroll: 2, infinite: false }
            }
        ]
    };

    useEffect(() => {
        setLoadingEvents(true);
        apiRequest
            .get("/v1/events")
            .then((res) => {
                let dataSet = [];
                let fullData = res.data.data;
                if (fullData.length > 0){
                    fullData.forEach((item) => {
                        dataSet.push({
                            neBanner:item.image_name,
                            neTopic: item.topic,
                            neTitle: item.title,
                            neLink: item.url_inscription,
                            neLinkLive: item.url_live,
                            neDate:item.date_str,
                            neDateDDE:item.date_dde,
                            neCreatedAt:item.createdAt,
                            neText: item.description,
                            neInfo: JSON.parse(item.speakers),
                            neStatus: item.event_status,
                        });
                    });
                    setEvents(dataSet)

                }
            })
            .catch((err) => {
                console.log(err);
            }).finally(()=>{
            setLoadingEvents(false);
        });

    }, []);

    useEffect(() => {
        setLoadingVideos(true);
        apiRequest
            .get("/v1/events/highlights")
            .then((res) => {
                let dataSet = [];
                let fullData = res.data.data;
                if (fullData.length > 0){
                    fullData.forEach((item) => {
                        dataSet.push({
                            url: item.url_live,
                            title:item.title
                        });
                    });
                    setVideos(dataSet)
                }
            })
            .catch((err) => {
                console.log(err);
            }).finally(()=>{
            setLoadingVideos(false);
        });

    }, []);


    const page = (
        <>
            <ProductBanner
                bannerContainerClassName="bannerEventos"
                bannerTrasparecy="bannerMask"
                productTitle="Eventos"
                bannerImage={Banner}
                bannerImageClassName="eventosBannerImage"
                textContainerClassName="textContainer"
                bannerText={text}
                bannerTextClassName="bannerText2"
                bannerImageMobile={Banner}
                mobile={false}
            />
            <SalWrapper>
            {nextEvents.length > 0 && (
            <section className="fullwidth proximos-eventos">
                <Container>
                       <ProximosEventos
                       nextEvents={events}
                       />
                </Container>
            </section>
            )}

            {
                videos.length > 0 && (
            <section className="fullwidth eventos-destacados">
                <Container>
                    <Row className="justify-content-center g-3 g-md-3">
                        <h3 className="ash2">Eventos destacados</h3>
                        <SlickVideoSlider linkList={videos} slickSettings={slickSettings} />
                    </Row>
                </Container>
            </section>
            )}
            <section className="fullwidthbg mira-eventos part-blue pt-2 pb-5">
                <Container>
                    <Row>
                        <Col xs={12} md={7} lg={7}  className="column col-text">
                            <div className="section-content pt-sm-5 pt-4">
                                <div className="my-3"
                                     data-sal="fade"
                                     data-sal-delay="0"
                                     data-sal-duration="200"
                                ><img alt="suscribite" src={YTicon} width="54" height="38" /></div>
                                <h2 className="titulo"
                                    data-sal="slide-up"
                                    data-sal-delay="0"
                                    data-sal-duration="200"
                                >Mirá todos los eventos en nuestro canal de YouTube. Suscribite para no perderte ninguno. </h2>
                                <div className="cta py-3 py-sm-4 py-md-5"
                                     data-sal="slide-up"
                                     data-sal-delay="0"
                                     data-sal-duration="200"
                                >
                                    <Button variant="nofill-2" text="Accedé al canal" onClick={() => { window.open("https://www.youtube.com/channel/UCl7iljPb3wACm-lOyqM1dHA", "_blank") }} />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={5} className="column col-img">
                            <div className="section-content"
                                 data-sal="slide-up"
                                 data-sal-delay="200"
                                 data-sal-duration="200"
                            >
                                <img
                                    className="section-image lift ratio ratio-1x1"
                                    src={EventImage}
                                    alt="Independent Financial Advisors"
                                />
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
            <section className="fullwidth suscribe"
                     data-sal="fade"
                     data-sal-delay="0"
                     data-sal-duration="200"
            >
                <Container>
                    <SuscribeTalks
                        ClassNameDisplay="section-content"
                        ClassNameInput="custom-email-input"
                        ButtonStyle="secondary"
                        IDCampaignAction={1055}
                    />
                </Container>
            </section>
            </SalWrapper>
        </>
    );

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"eventos"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default EventosPage;